import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link, Box, IconButton, InputAdornment, TextField, Checkbox, Typography, Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import Iconify from '../iconify';
import { useState } from 'react';
import { useEffect } from 'react';


export default function AddQuestion({ title, description, open, setOpen, onSubmit, onCancel ,selectedData}) {
    const [option1, setOption1] = useState("")
    const [option2, setOption2] = useState("")
    const [option3, setOption3] = useState("")
    const [option4, setOption4] = useState("")
    const [qn, setQn] = useState("")

    useEffect(()=>{
        if(open){
            if(selectedData){
                setOption1(selectedData?.options[0]?.value??"")
                setOption2(selectedData?.options[1]?.value??"")
                setOption3(selectedData?.options[2]?.value??"")
                setOption4(selectedData?.options[3]?.value??"")
                setQn(selectedData?.qn)
            }else{
                setOption1("")
                setOption2("")
                setOption3("")
                setOption4("")
                setQn("")
            }
        }else{
            setOption1("")
            setOption2("")
            setOption3("")
            setOption4("")
            setQn("")
        }
        
    },[open,selectedData])

    return (
        <Dialog
            open={open}
            onClose={() => { setOpen(false) }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {description}
                </DialogContentText>

                <Box>
                    <TextField value={qn} onChange={e => {
                        setQn(e.target.value)
                    }} placeholder='Question' fullWidth sx={{}} />
                    <Box>
                        <Stack direction={"row"}>
                            <TextField value={option1} onChange={e => {
                                setOption1(e.target.value)
                            }} multiline sx={{ marginTop: "5px" }} placeholder="Option 1" />
                            <TextField value={option2} onChange={e => {
                                setOption2(e.target.value)
                            }} multiline sx={{ marginTop: "5px", marginLeft: "10px" }} placeholder="Option 2" />
                        </Stack>
                        <Stack direction={"row"}>
                            <TextField value={option3} onChange={e => {
                                setOption3(e.target.value)
                            }} multiline sx={{ marginTop: "5px" }} placeholder="Option 3" />
                            <TextField value={option4} onChange={e => {
                                setOption4(e.target.value)
                            }} multiline sx={{ marginTop: "5px", marginLeft: "10px" }} placeholder="Option 4" />
                        </Stack>
                    </Box>
                </Box>

            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Cancel</Button>
                <Button onClick={() => {
                    onSubmit(qn, [{value:option1,level:1}, {value:option2,level:2}, {value:option3,level:3}, {value:option4,level:4}])
                }} autoFocus>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}