// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
    v: "dashboard"
  },
  {
    title: 'Users',
    path: '/dashboard/user',
    icon: icon('ic_user'),
    v: "users"
  },
 
  {
    title: 'Feeds',
    path: '/dashboard/feeds',
    icon: icon('ic_blog'),
    v: "feeds"
  },
  {
    title: 'Pages',
    path: '/dashboard/blog',
    icon: icon('ic_blog'),
    v: "pages"
  },
  // {
  //   title: 'Intros',
  //   path: '/dashboard/intros',
  //   icon: icon('ic_blog'),
  //   v: "intros"
  // },
  {
    title: 'Questions',
    path: '/dashboard/products',
    icon: icon('ic_cart'),
    v: "questions"
  },
  {
    title: 'Settings',
    path: '/dashboard/settings',
    icon: icon('ic_lock'),
    v: "settings"
  },
  {
    title: 'Contact Us',
    path: '/dashboard/supports',
    icon: icon('ic_help'),
    v: "supports"
  },

  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
