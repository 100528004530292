// src/components/UserResults.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const UserResults = ({ userId }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/v1/results/user/${userId}/results`);
                setData(response.data);
            } catch (error) {
                setError(error);
            }
        };

        fetchData();
    }, [userId]);

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (!data) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h1>User Results</h1>
            <div>
                <h2>Results</h2>
                <ul>
                    {data.results.map(result => (
                        <li key={result._id}>
                            Test Type: {result.testType}, Value: {result.value}
                        </li>
                    ))}
                </ul>
            </div>
            <div>
                <h2>Sub Results</h2>
                <ul>
                    {data.subResults.map(subResult => (
                        <li key={subResult._id}>
                            Test Type: {subResult.testType}, Sub Test Type: {subResult.subTestType}, Value: {subResult.value}, Point: {subResult.point}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default UserResults;