import { configureStore } from '@reduxjs/toolkit'

import auth from './auth'
import orders from './orders'
import users from './users'
import miscdata from './miscdata'
import toast from './toast'
import loader from './loader'
import supports from './support'
import navs from './navs'
import faqs from './faq'
import questions from './questions'
import feeds from './feeds'
const store = configureStore({
    reducer: {
        auth,
        orders,
        users,
        miscdata,
        toast,
        loader,
        supports,
        navs,
        faqs,
        questions,
        feeds
    }
})

export { store }