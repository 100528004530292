import { useEffect, useRef, useState } from 'react';

import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Button, Container, Stack, Box, TextField, Typography } from '@mui/material';
import JoditEditor from 'jodit-react';
import { useDispatch, useSelector } from 'react-redux';
import hit from 'src/services/manager';
import { setLoader } from 'src/redux/loader';
import { setMiscData } from 'src/redux/miscdata';
import { setToast } from 'src/redux/toast';
import { getFAQListThunk } from 'src/redux/faq';
import { useLocation } from 'react-router-dom';

// components
// mock

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

export default function BlogPage() {
  const [selected, setSelected] = useState(0)
  const editor = useRef(null);
  const editor1 = useRef(null);
  const editor2 = useRef(null);
  const editor3 = useRef(null);
  const editor4 = useRef(null);
  const editor5 = useRef(null);
  const faqs = useSelector(state => state.faqs)?.list

  const [terms, setTerms] = useState("")
  const [policy, setPolicy] = useState("")
  const [about, setAbout] = useState("")
  const miscdata = useSelector(state => state.miscdata)?.data
  const [landing, setLanding] = useState({
    about: "",
    why: "",
    contact: ""
  })
  const dispatch = useDispatch()
  const writes = useSelector(state => state.navs)?.write
  const user = useSelector(state => state.auth)?.user
  const [qn, setQn] = useState("")
  const [ans, setAns] = useState("")
  const location=useLocation()
  useEffect(() => {
    dispatch(getFAQListThunk())
  }, [location])
  useEffect(() => {
    if (miscdata?.terms) {
      setTerms(miscdata?.terms)
      setPolicy(miscdata?.policy)
      setAbout(miscdata?.about)
      if(miscdata?.landing?.about||miscdata?.landing?.why||miscdata?.landing?.contact){
        setLanding(miscdata?.landing)
      }
    }
  }, [miscdata])

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/,
    placeholder: 'Start typings...'
  }

  const submit = async () => {
    try {
      dispatch(setLoader(true))
      let res = await hit("/admin/misc", "post", { terms, policy, about ,landing})
      if (!res.err) {
        dispatch(setMiscData(res.data))
        dispatch(setToast({ show: true, message: "Updated Successfully!", severity: "success" }))

      } else {
        dispatch(setToast({ show: true, message: res?.msg, severity: "error" }))
      }
    } catch (err) {

    } finally {
      dispatch(setLoader(false))

    }
  }
  const submitFAQ = async () => {
    try {
      dispatch(setLoader(true))
      let res = await hit("/faqs", "post", { ans, qn })
      if (!res.err) {
        dispatch(getFAQListThunk())
        dispatch(setToast({ show: true, message: "Added Successfully!", severity: "success" }))
        setQn("")
        setAns("")
      } else {
        dispatch(setToast({ show: true, message: res?.msg, severity: "error" }))
      }
    } catch (err) {

    } finally {
      dispatch(setLoader(false))

    }
  }

  const deleteFAQ = async (id) => {
    try {
      dispatch(setLoader(true))
      let res = await hit("/faqs/" + id, "delete", {})
      if (!res.err) {
        dispatch(getFAQListThunk())
        dispatch(setToast({ show: true, message: "deleted Successfully!", severity: "success" }))

      } else {
        dispatch(setToast({ show: true, message: res?.msg, severity: "error" }))
      }
    } catch (err) {

    } finally {
      dispatch(setLoader(false))

    }
  }
  return (
    <>
      <Helmet>
        <title> Dashboard: Pages | SuperSenses </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" mb={5}>
          <Button color={selected == 0 ? "primary" : "secondary"} onClick={() => {
            setSelected(0)
          }} variant="contained" >
            Privacy Policy
          </Button>
          <Button onClick={() => {
            setSelected(1)
          }} color={selected == 1 ? "primary" : "secondary"} variant="contained" sx={{ mx: "10px" }}>
            Terms and Conditions
          </Button>
          <Button onClick={() => {
            setSelected(2)
          }} color={selected == 2 ? "primary" : "secondary"} variant="contained" >
            About Us
          </Button>
          {/* <Button onClick={() => {
            setSelected(3)
          }} color={selected == 3 ? "primary" : "secondary"} variant="contained" sx={{ mx: "10px" }} >
            Landing Page
          </Button> */}
          <Button onClick={() => {
            setSelected(4)
          }} color={selected == 4 ? "primary" : "secondary"} variant="contained" sx={{ mx: "10px" }} >
            FAQ
          </Button>
        </Stack>
        <div>
          {selected == 0 && <JoditEditor
            ref={editor}
            value={terms}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onBlur={newContent => setTerms(newContent)} // preferred to use only this option to update the content for performance reasons
            onChange={newContent => { }}
          />}
          {selected == 1 && <JoditEditor
            ref={editor1}
            value={policy}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onBlur={newContent => setPolicy(newContent)} // preferred to use only this option to update the content for performance reasons
            onChange={newContent => { }}
          />}
          {selected == 2 && <JoditEditor
            ref={editor2}
            value={about}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onBlur={newContent => setAbout(newContent)} // preferred to use only this option to update the content for performance reasons
            onChange={newContent => { }}
          />}
          {selected == 3 && <>
          <Typography variant='h5'>About Us</Typography>
            {/* <TextField
              value={landing.about}
              onChange={e => {
                setLanding({ ...landing, about: e.target.value })
              }}
              fullWidth
              style={{ height: "200px" }}
              multiline
              minRows={5}
            /> */}
            <JoditEditor
            ref={editor3}
            value={landing.about}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onBlur={newContent => {
              setLanding({ ...landing, about: newContent })
            }} // preferred to use only this option to update the content for performance reasons
            onChange={newContent => { }}
          />
            <Typography variant='h5'>Why SuperSenses?</Typography>
            {/* <TextField
              value={landing.why}
              onChange={e => {
                setLanding({ ...landing, why: e.target.value })
              }}
              fullWidth
              style={{ height: "200px" }}
              multiline
              minRows={5}
            /> */}
            <JoditEditor
            ref={editor4}
            value={landing.why}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onBlur={newContent => {
              setLanding({ ...landing, why: newContent })
            }} // preferred to use only this option to update the content for performance reasons
            onChange={newContent => { }}
          />
            <Typography variant='h5'>Contact Us</Typography>
            {/* <TextField
              value={landing.contact}
              onChange={e => {
                setLanding({ ...landing, contact: e.target.value })
              }}
              fullWidth
              style={{ height: "200px" }}
              multiline
              minRows={5}
            /> */}
            <JoditEditor
            ref={editor5}
            value={landing.contact}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onBlur={newContent => {
              setLanding({ ...landing, contact: newContent })
            }} // preferred to use only this option to update the content for performance reasons
            onChange={newContent => { }}
          />
            </>
          }
          {selected == 4 && <>
            <Box sx={{
              boxShadow: "1px 10px 55px -12px rgba(0,0,0,0.53)",
              "-webkit-box-shadow": "1px 10px 55px -12px rgba(0,0,0,0.53)",
              "-moz-box-shadow": "1px 10px 55px -12px rgba(0,0,0,0.53)",
              marginTop: "20px",
              borderRadius: "5px",
              padding: "10px",
              maxWidth: "700px",
              display: "flex", alignItems: "center",
              justifyContent: "space-between"
            }}>
              <Box sx={{ display: "flex", flexDirection: "column", flex: 1, marginRight: "5px" }}>
                <TextField value={qn} onChange={e => {
                  setQn(e.target.value)
                }} placeholder='Question' />
                <TextField value={ans} onChange={e => {
                  setAns(e.target.value)
                }} multiline sx={{ marginTop: "5px" }} placeholder="Answer" />
              </Box>
              <Button
                variant="contained"
                onClick={() => {
                  if (user?.role == "admin") {
                    submitFAQ()
                  } else if (user?.role == "staff") {
                    if (writes?.includes("pages")) {
                      submitFAQ()
                    } else {
                      dispatch(setToast({ show: true, message: "Sorry you are not authorised!", severity: "error" }))

                    }
                  }

                }}
              >
                Add
              </Button>
            </Box>
            {faqs.map((x, i) => (
              <Box sx={{
                boxShadow: "1px 10px 55px -12px rgba(0,0,0,0.53)",
                "-webkit-box-shadow": "1px 10px 55px -12px rgba(0,0,0,0.53)",
                "-moz-box-shadow": "1px 10px 55px -12px rgba(0,0,0,0.53)",
                marginTop: "20px",
                borderRadius: "5px",
                padding: "10px",
                maxWidth: "700px",
                display: "flex", alignItems: "center"
              }}>
                <Typography>{i + 1}) </Typography>
                <Box sx={{ flex: 1, marginLeft: "10px" ,whiteSpace: "pre-line"}} >
                  <Typography sx={{ fontSize: "1.5em", fontWeight: "600" }}>{x?.qn}</Typography>
                  <Typography>{x?.ans}</Typography>
                </Box>
                <Button onClick={() => {
                  // 
                  if (user?.role == "admin") {
                    deleteFAQ(x?._id)
                  } else if (user?.role == "staff") {
                    if (writes?.includes("pages")) {
                      deleteFAQ(x?._id)
                    } else {
                      dispatch(setToast({ show: true, message: "Sorry you are not authorised!", severity: "error" }))

                    }
                  }
                }} variant='contained' color='error'>
                  Delete
                </Button>
              </Box>
            ))}
          </>
          }
        </div>
        {selected!=4&&<Box display="flex" justifyContent={"center"} mt={4}>
          <Button onClick={() => {
            if (user?.role == "admin") {
              submit()
            } else if (user?.role == "staff") {
              if (writes?.includes("subs")) {
                submit()
              } else {
                dispatch(setToast({ show: true, message: "Sorry you are not authorised!", severity: "error" }))

              }
            }
          }} variant='contained' >
            Submit
          </Button>
        </Box>}
      </Container>
    </>
  );
}
