// src/pages/UserDetailView.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import UserResults from '../components/userResults';

const UserDetailView = () => {
    const { userId } = useParams();
    const [userInfo, setUserInfo] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await fetch(`/v1/users/${userId}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setUserInfo(data);
            } catch (error) {
                setError(error);
            }
        };

        fetchUserInfo();
    }, [userId]);

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (!userInfo) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h1>User Details</h1>
            <div>
                <h2>User Information</h2>
                <ul>
                    <li>Name: {userInfo.firstName}</li>
                    <li>Email: {userInfo.email}</li>
                    <li>Phone: {userInfo.phone}</li>
                    <li>Address: {userInfo.address}</li>
                    <li>Login Type: {userInfo.loginType}</li>
                    <li>Registered: {new Date(userInfo.createdAt).toLocaleDateString()}</li>
                    <li>Last Login: {new Date(userInfo.lastOpenedApp).toLocaleDateString()}</li>
                    <li>User ID: {userInfo._id}</li>
                    <li>Status: {userInfo.status}</li>
                </ul>
            </div>
            <UserResults userId={userId} />
            <div>
                <h2>Change Log</h2>
                <ul>
                    {userInfo.changeLog && userInfo.changeLog.map((log, index) => (
                        <li key={index}>
                            {log.timestamp}: {log.change}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default UserDetailView;