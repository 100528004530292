import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Box,
    Select,
    InputLabel,
    FormControl,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import { useDispatch, useSelector } from 'react-redux';
import { image_base_url } from 'src/services/endpoints';
import moment from 'moment'
import AskDialog from 'src/components/ask';
import hit from 'src/services/manager';
import { setLoader } from 'src/redux/loader';
import { setToast } from 'src/redux/toast';
import { getAllFeeds, setList } from 'src/redux/feeds';
import { useLocation, useNavigate } from 'react-router-dom';
import AddFeed from 'src/components/addFeed';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'headline', label: 'Headline', alignRight: false },
    { id: 'category', label: 'Category', alignRight: false },
    { id: 'text', label: 'Message', alignRight: false },
    // { id: 'icon', label: 'Icon', alignRight: false },
    { id: 'image', label: "Image", alignRight: false },
    // { id: 'rating', label: "Rating", alignRight: false },
    { id: 'sharelink', label: "ShareLink", alignRight: false },
    { id: 'test', label: "Test", alignRight: false },
    { id: 'conditions', label: "Conditions", alignRight: false },
    { id: 'createdAt', label: "Created At", alignRight: false },
    { id: 'action', label: "Action", alignRight: true },

];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => (_user?.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 || String(_user?.phone)?.includes(query.toLowerCase()) || _user?.email?.toLowerCase()?.includes(query.toLowerCase())));
    }
    return stabilizedThis.map((el) => el[0]);
}

const conditions = [
    { value: 4, label: "Super" },
    { label: "Dominant", value: 3, },
    { label: "Normal", value: 2, },
    { label: "Low", value: 1, },
    { label: "All", value: 100 }
]

const tests = [
    { label: "Hearing", value: 0 },
    { label: "Taste", value: 1, },
    { label: "Touch", value: 2, },
    { label: "Smell", value: 3, },
    { label: "Vision", value: 4, },
    { label: "All", value: 100 }
]

const categorys = [
    { label: "About You", value: 1 },
    { label: "Try This", value: 2 },
    { label: "Your Sensory Tale", value: 4 },
    { label: "Sensory Journeys", value: 5 },
    { label: "Evolutionary Gifts", value: 6 },
    { label: "Sage Advice", value: 7 },
    { label: "Wonders of Science", value: 8 },
    { label: "Your Advantage", value: 9 },
    { label: "Did you Know?", value: 10 },
    { label: "Your Health", value: 11 },
    { label: "Pro Tip", value: 12 },
    { label: "All", value: 100 }
]


export default function FeedPage() {
    const [open, setOpen] = useState(null);

    const [page, setPage] = useState(0);
    const dispatch = useDispatch()
    const [order, setOrder] = useState('desc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('createdAt');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const feeds = useSelector(state => state.feeds)?.list

    const [open1, setOpen1] = useState(false)
    const [open2, setOpen2] = useState(false)

    const [filterData, setFilterData] = useState({
        category: 100,
        condition: 100,
        test: 100
    })

    const [selectedData, setSelectedData] = useState({
        status: 0,
        _id: null
    })

    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };
    const location = useLocation()
    useEffect(() => {
        dispatch(getAllFeeds())
    }, [location])
    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = feeds?.map((n) => n?.firstName);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };



    const deleteFeed = async () => {
        try {
            dispatch(setLoader(true))
            let res = await hit("feeds/" + selectedData?._id, "delete",)
            if (!res.err) {
                dispatch(setList(feeds.filter(x => x._id != selectedData?._id)))
                dispatch(getAllFeeds())
            } else {
                dispatch(setToast({ show: true, message: res.msg, severity: "error" }))
            }
        } catch (err) {

        } finally {
            dispatch(setLoader(false))

        }
    }

    const user = useSelector(state => state.auth)?.user
    const navs = useSelector(state => state.navs)?.write

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - feeds?.length) : 0;

    let filteredFeeds = applySortFilter(feeds, getComparator(order, orderBy), filterName);

    if(filterData?.category!=100){
        filteredFeeds=filteredFeeds.filter(x=>x?.category==filterData?.category)
    }
    if(filterData?.condition!=100){
        filteredFeeds=filteredFeeds.filter(x=>x?.condition==filterData?.condition)
    }
    if(filterData?.test!=100){
        filteredFeeds=filteredFeeds.filter(x=>x?.test==filterData?.test)
    }

    const isNotFound = !filteredFeeds.length && !!filterName;
    const navigate = useNavigate()

    return (
        <>
            <Helmet>
                <title> Feeds | SuperSenses UI </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="flex-start" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Feeds
                    </Typography>
                    <Button onClick={() => {
                        setOpen1(true)
                    }} variant="contained" sx={{ marginLeft: "20px" }} startIcon={<Iconify icon="eva:plus-fill" />}>
                        Add Feed
                    </Button>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="flex-start" mb={5}>
                    <Typography variant="h6">Filter</Typography>
                    <Stack direction={"row"} marginLeft={"20px"} style={{ width: "500px" }}>
                        <FormControl fullWidth>
                            <InputLabel id="select-1">Test</InputLabel>
                            <Select
                                labelId="select-1"
                                id="demo-simple-select"
                                value={filterData.test}
                                label="Test"
                                onChange={(e) => {
                                    setFilterData({ ...filterData, test: e.target.value })
                                }}
                            >
                                {tests?.map(x => {
                                    return <MenuItem value={x.value}>{x.label}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ marginLeft: "10px" }} fullWidth>
                            <InputLabel id="select-2">Category</InputLabel>
                            <Select
                                labelId="select-2"
                                id="demo-simple-select1"
                                value={filterData.category}
                                label="Category"
                                onChange={(e) => {
                                    setFilterData({ ...filterData, category: e.target.value })
                                }}
                            >
                                {categorys?.map(x => {
                                    return <MenuItem value={x.value}>{x.label}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ marginLeft: "10px" }} fullWidth>
                            <InputLabel id="select-2">Condition</InputLabel>
                            <Select
                                labelId="select-2"
                                id="demo-simple-select1"
                                value={filterData.condition}
                                label="Category"
                                onChange={(e) => {
                                    setFilterData({ ...filterData, condition: e.target.value })
                                }}
                            >
                                {conditions?.map(x => {
                                    return <MenuItem value={x.value}>{x.label}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Stack>
                </Stack>
                <Card>
                    {/* <FeedListToolbar numSelected={selected.length} show={false} filterName={filterName} onFilterName={handleFilterByName} /> */}
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={feeds.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredFeeds.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        const { _id, category, headline, text, icon, image, shareLink, condition, test, createdAt } = row;
                                        const selectedFeed = selected.indexOf(_id) !== -1;

                                        return (
                                            <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedFeed}>
                                                <TableCell padding="checkbox">
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none">
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        {/* <Avatar alt={name} src={image_base_url + profilePic} /> */}
                                                        <Typography style={{ maxWidth: "100px", height: "100px", overflow: "scroll" }}  >
                                                            {headline}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="left">{categorys?.find(x => x.value == category)?.label}</TableCell>
                                                <TableCell align="left" >
                                                    <Typography sx={{ maxLines: 4, height: "100px", overflow: "scroll" }}>{text}</Typography>
                                                </TableCell>
                                                {/* <TableCell align="left">
                                                    <Avatar src={image_base_url + icon} />
                                                </TableCell> */}
                                                <TableCell align="left">
                                                    {image ? <Avatar src={image_base_url + image} style={{ width: "200px", height: "100px" ,borderRadius:0,objectFit:"contain"}} /> : "None"}
                                                </TableCell>
                                                {/* <TableCell align="left">{rating}</TableCell> */}
                                                <TableCell align="left"  ><a href={shareLink} target="_blank">{shareLink}</a></TableCell>
                                                <TableCell align="left">
                                                    {tests?.find(x => x.value == test)?.label}
                                                </TableCell>
                                                <TableCell align='left'>
                                                    {conditions?.find(x => x.value == condition)?.label}
                                                </TableCell>
                                                <TableCell align='left'>
                                                    {moment(createdAt)?.format("DD/MM/YYYY hh:mm a")}
                                                </TableCell>
                                                <TableCell align="right" sx={{ display: "flex", flexDirection: "column-reverse", }}>
                                                    <Button onClick={() => {
                                                        setSelectedData(row)
                                                        setOpen2(true)
                                                    }} sx={{ marginY: "5px" }} color={'error'} variant="contained" >Delete</Button>
                                                    <Button onClick={() => {
                                                        setSelectedData(row)
                                                        setOpen1(true)
                                                    }} color={"primary"} variant="contained" >{sentenceCase("Update")}</Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={feeds.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
            <AskDialog open={open2} title="Feed Delete" description={`Do you want to delete this feed?`} setOpen={setOpen2} onCancel={() => {
                setOpen2(false)
            }} onOk={() => {
                setOpen2(false)
                deleteFeed()
                // deactivate()
            }} />
            <AddFeed open={open1} selectedData={selectedData} title={selectedData?._id?"Update Feed":"Add Feed"} description={`Add details`} setOpen={setOpen1} onCancel={() => {
                setOpen1(false)
                setSelectedData({ _id: null })
            }} onSubmit={() => {
                setOpen1(false)
                setSelectedData({ _id: null })
                dispatch(getAllFeeds())
                // deactivate()
            }} />
        </>
    );
}
