import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TextField,
  Box,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import { useDispatch, useSelector } from 'react-redux';
import { image_base_url } from 'src/services/endpoints';
import moment from 'moment'
import FullScreenDialog from 'src/components/fullDailog';
import CustomDropDown from 'src/components/dropdown';
import { useLocation } from 'react-router-dom';
import { getOrdersListThunk } from 'src/redux/orders';
import AddQuestion from 'src/components/addQuestion';
import { getQuestionsThunk } from 'src/redux/questions';
import { setToast } from 'src/redux/toast';
import { setLoader } from 'src/redux/loader';
import hit from 'src/services/manager';
// ----------------------------------------------------------------------




export default function OrdersPage() {
  const [selected, setSelected] = useState(0);
  const [open, setOpen] = useState(false)
  const questions = useSelector(state => state.questions)?.list
  const dispatch = useDispatch()
  const location = useLocation()
  const [currentList, setCurrentList] = useState([])
  const user = useSelector(state => state.auth?.user)
  const writes = useSelector(state => state.navs)?.write
  const [selectedId, setSelectedId] = useState(null)
  useEffect(() => {
    setCurrentList(questions.filter(x => x?.type == selected))
  }, [questions, selected])
  useEffect(() => {
    dispatch(getQuestionsThunk())
  }, [location])


  const submitQn = async (data) => {
    try {
      dispatch(setLoader(true))
      let res = await hit("/interviews", "post", data)
      if (!res.err) {
        dispatch(getQuestionsThunk())
        dispatch(setToast({ show: true, message: "Added Successfully!", severity: "success" }))
      } else {
        dispatch(setToast({ show: true, message: res?.msg, severity: "error" }))
      }
    } catch (err) {

    } finally {
      dispatch(setLoader(false))

    }
  }

  const deleteQN = async (id) => {
    try {
      dispatch(setLoader(true))
      let res = await hit("/interviews/" + id, "delete", {})
      if (!res.err) {
        dispatch(getQuestionsThunk())
        dispatch(setToast({ show: true, message: "deleted Successfully!", severity: "success" }))

      } else {
        dispatch(setToast({ show: true, message: res?.msg, severity: "error" }))
      }
    } catch (err) {

    } finally {
      dispatch(setLoader(false))

    }
  }
  const updateQn = async (id,data) => {
    try {
      dispatch(setLoader(true))
      let res = await hit("/interviews/" + id, "patch", data)
      if (!res.err) {
        dispatch(getQuestionsThunk())
        dispatch(setToast({ show: true, message: "updated Successfully!", severity: "success" }))

      } else {
        dispatch(setToast({ show: true, message: res?.msg, severity: "error" }))
      }
    } catch (err) {

    } finally {
      dispatch(setLoader(false))

    }
  }

  return (
    <>
      <Helmet>
        <title>Question | SuperSenses</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Questions
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" mb={5}>
          <Button onClick={() => {
            setSelected(0)
          }} color={selected == 0 ? "primary" : "secondary"} variant="contained" sx={{ marginRight: "10px" }} >
            Hearing
          </Button>
          <Button color={selected == 1 ? "primary" : "secondary"} onClick={() => {
            setSelected(1)
          }} variant="contained" >
            Taste
          </Button>
          <Button onClick={() => {
            setSelected(2)
          }} color={selected == 2 ? "primary" : "secondary"} variant="contained" sx={{ mx: "10px" }}>
            Touch
          </Button>
          <Button onClick={() => {
            setSelected(3)
          }} color={selected == 3 ? "primary" : "secondary"} variant="contained" >
            Smell
          </Button>
          <Button onClick={() => {
            setSelected(4)
          }} color={selected == 4 ? "primary" : "secondary"} variant="contained" sx={{ mx: "10px" }} >
            Visual
          </Button>

        </Stack>
        <Button onClick={() => {
          if (user?.role == "admin") {
            setOpen(true)
          } else if (user?.role == "staff") {
            if (writes?.includes("question")) {
              setOpen(true)
            } else {
              dispatch(setToast({ show: true, message: "Sorry you are not authorised!", severity: "error" }))

            }
          }

        }} color={"primary"} variant="contained" sx={{}} >
          Add Question
        </Button>
        <Card sx={{ marginTop: "10px" }}>
          <Scrollbar>
            {currentList.map((x, i) => (
              <Box sx={{

                marginTop: "20px",
                borderRadius: "5px",
                padding: "10px",
                display: "flex", alignItems: "center"
              }}>
                <Typography>{i + 1}) </Typography>
                <Box sx={{ flex: 1, marginLeft: "10px", whiteSpace: "pre-line" }} >
                  <Typography sx={{ fontSize: "1em", fontWeight: "600" }}>{x?.qn}</Typography>
                  <Typography><span style={{ fontWeight: "500" }}>Options</span> {x?.options?.map((x, i) => (<span style={{ marginLeft: "10px" }}>{i + 1}. {x?.value}</span>))} </Typography>
                </Box>
                <Button onClick={() => {
                  // 
                  if (user?.role == "admin") {
                    setOpen(true)
                    setSelectedId(x?._id)
                  } else if (user?.role == "staff") {
                    if (writes?.includes("question")) {
                      setOpen(true)
                      setSelectedId(x?._id)
                    } else {
                      dispatch(setToast({ show: true, message: "Sorry you are not authorised!", severity: "error" }))
                    }
                  }
                }} variant='contained' sx={{ marginRight: "10px" }} color="secondary">
                  Edit
                </Button>
                <Button onClick={() => {
                  // 
                  if (user?.role == "admin") {
                    deleteQN(x?._id)
                  } else if (user?.role == "staff") {
                    if (writes?.includes("question")) {
                      deleteQN(x?._id)
                    } else {
                      dispatch(setToast({ show: true, message: "Sorry you are not authorised!", severity: "error" }))

                    }
                  }
                }} variant='contained' color='error'>
                  Delete
                </Button>
              </Box>
            ))}
          </Scrollbar>
        </Card>
      </Container>
      <AddQuestion selectedData={questions?.find(x=>x._id==selectedId)} title={ selectedId!=null?"Update Question":"Add Question"} description={""} setOpen={setOpen} onCancel={() => { setOpen(false);setSelectedId(null) }} onSubmit={(qn, options) => {
        setOpen(false)
        if(selectedId){
          updateQn(selectedId,{ options, qn, type: selected })
        }else{
          submitQn({ options, qn, type: selected })
        }
       
        setSelectedId(null)
      }} open={open} />
    </>
  );
}
