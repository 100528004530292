import {createSlice} from '@reduxjs/toolkit'
import hit from 'src/services/manager'

const initialState={
    list:[]
}

const supportSlice=createSlice({
    name:"support",
    initialState,
    reducers:{
        setSupportList:(state,action)=>{
            state.list=action.payload
        }
    }
})

export const {setSupportList}=supportSlice.actions

export const getSupportsThunk=()=>{
    return async(dispatch)=>{
        try{
            let res=await hit("/supports","get")
            if(!res.err){
                dispatch(setSupportList(res?.data))
            }
        }catch(err){

        }finally{

        }
    }
}

export default supportSlice.reducer