import { createSlice } from '@reduxjs/toolkit'
import hit from 'src/services/manager'

const initialState = {
    list: []
}

const feedSlice = createSlice({
    name: "feeds",
    initialState,
    reducers: {
        setList: (state, action) => {
            state.list = action.payload
        }
    }
})

export const { setList } = feedSlice.actions

export const getAllFeeds = () => {
    return async (dispatch) => {
        try {
            let res=await hit("/feeds/admin","get")
            if(!res?.err){
                dispatch(setList(res.data))
            }
        } catch (err) {

        } finally {

        }
    }
}

export default feedSlice.reducer