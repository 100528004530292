import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { getSupportsThunk } from 'src/redux/support';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const users = useSelector(state => state.users)?.list
  const teams = useSelector(state => state.supports)?.list
  const navs = useSelector(state => state.navs)
  const user = useSelector(state => state.auth)?.user
  const navigate = useNavigate()
  const [chartLabel, setChartLabel] = useState([])
  const [androidsGraph, setAndroidGraphs] = useState([])
  const [iosGraphs, setIosGraphs] = useState([])
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getSupportsThunk())
  }, [])
  useEffect(() => {
    let year = moment().format("YYYY")
    let cs = [
      '01/01/' + year,
      '02/01/' + year,
      '03/01/' + year,
      '04/01/' + year,
      '05/01/' + year,
      '06/01/' + year,
      '07/01/' + year,
      '08/01/' + year,
      '09/01/' + year,
      '10/01/' + year,
      '11/01/' + year,
    ]
    let as = []
    let is = []
    for (let i of cs) {
      let androids = users.filter(x => x.os == "android")
      let ioss = users.filter(x => x.os == "ios")
      console.log(androids, ioss)
      as.push(androids.filter(x => moment(x.createdAt).format("MM/[01]/YYYY") == i).length)
      is.push(ioss.filter(x => moment(x.createdAt).format("MM/[01]/YYYY") == i).length)
    }
    setChartLabel(cs)
    setAndroidGraphs(as)
    setIosGraphs(is)
  }, [users])
  useEffect(() => {
    if (navs && user?.role == "staff") {
      if (navs?.read?.filter(x => x == "dashboard")?.length == 0) {
        if (navs?.navConfig[0]) {
          navigate(navs?.navConfig[0]?.path, { replace: true })
        }
      }
    }
  }, [user])
  return (
    <>
      <Helmet>
        <title> Dashboard | SuperSenses</title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total Users" total={users?.length} color="secondary" icon={'ant-design:bug-filled'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Android Users" total={users?.filter(x => x.os == "android").length} icon={'ant-design:android-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="IOS Users" total={users?.filter(x => x.os == "ios").length ?? "0"} color="info" icon={'ant-design:apple-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Supports" total={teams?.length} color="warning" icon={'mdi:package-outline'} />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="User Created On"
              // subheader="(+43%) than last year"
              chartLabels={chartLabel}
              chartData={[
                {
                  name: 'IOS',
                  type: 'column',
                  fill: 'solid',
                  data: iosGraphs,
                },
                {
                  name: 'Android',
                  type: 'area',
                  fill: 'gradient',
                  data: androidsGraph,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Total Users"
              chartData={[
                { label: 'IOS', value: users?.filter(x => x.os == "ios").length ?? "0" },
                { label: 'Android', value: users?.filter(x => x.os == "android").length ?? "0" },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
