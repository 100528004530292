import { Helmet } from 'react-helmet-async';
import { filter, toPath } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import { useDispatch, useSelector } from 'react-redux';
import { image_base_url } from 'src/services/endpoints';
import moment from 'moment';
import AskDialog from 'src/components/ask';
import hit from 'src/services/manager';
import { setLoader } from 'src/redux/loader';
import { setToast } from 'src/redux/toast';
import { getAllUsers } from 'src/redux/users';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserDataThunk } from 'src/redux/auth';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'firstName', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'address', label: 'Address', alignRight: false },
  { id: 'loginType', label: "Login", alignRight: false },
  // { id: 'rating', label: "Rating", alignRight: false },
  { id: 'createdAt', label: "Registered", alignRight: false },
  { id: 'lastOpenedApp', label: "Last Login", alignRight: false },
  { id: '_id', label: "User ID", alignRight: false },
  { id: 'status', label: "Status", alignRight: false },
  { id: 'action', label: "Action", alignRight: true },
  { id: 'result', label: "Result", alignRight: true },
  { id: 'results', label: "Results", alignRight: true },
  { id: 'getResultData', label: "Result Data", alignRight: true },
  { id: 'subResult', label: "SubResult", alignRight: true },
  { id: 'subResults', label: "SubResults", alignRight: true },
  { id: 'subresultData', label: "SubResult Data", alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
    if (query) {
      return filter(array, (_user) => (_user?.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 || String(_user?.phone)?.includes(query.toLowerCase()) || _user?.email?.toLowerCase()?.includes(query.toLowerCase())));
    }
    return stabilizedThis.map((el) => el[0]);
  }

  export default function UserPage() {
    const [open, setOpen] = useState(null);
    const [page, setPage] = useState(0);
    const dispatch = useDispatch();
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('firstName');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const users = useSelector(state => state.users)?.list;
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [selectedData, setSelectedData] = useState({
      status: 0,
      _id: null,
    });
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      dispatch(getAllUsers());
    }, [location, dispatch]);

    const handleOpenMenu = (event) => {
      setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
      setOpen(null);
    };

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = users?.map((n) => n?.firstName);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    };

    const deactivate = async () => {
      try {
        dispatch(setLoader(true));
        let res = await hit("admin/users/" + selectedData?._id, "patch", { status: selectedData?.status === 0 ? 1 : 0 });
        if (!res.err) {
          dispatch(getAllUsers());
        } else {
          dispatch(setToast({ show: true, message: res.msg, severity: "error" }));
        }
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(setLoader(false));
      }
    };

    const deleteUser = async () => {
      try {
        dispatch(setLoader(true));
        let res = await hit("admin/users/" + selectedData?._id, "delete");
        if (!res.err) {
          dispatch(getAllUsers());
        } else {
          dispatch(setToast({ show: true, message: res.msg, severity: "error" }));
        }
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(setLoader(false));
      }
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setPage(0);
      setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
      setPage(0);
      setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

    const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredUsers.length && !!filterName;

    return (
      <>
        <Helmet>
          <title> Users | SuperSenses UI </title>
        </Helmet>

        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Users
            </Typography>
          </Stack>

          <Card>
            <UserListToolbar numSelected={selected.length} show={false} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={users.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                      const { _id, name, status, phone, profilePic, email, address, loginType, rating, lastOpenedApp, createdAt, result, results, resultData, subResult, subResults, subResultData } = row;
                      const selectedUser = selected.indexOf(_id) !== -1;

                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell padding="checkbox">
                            {/* <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, _id)} /> */}
                            {index + 1}
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={name} src={`${image_base_url}${profilePic}`} />
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">{phone}</TableCell>
                          <TableCell align="left">{address}</TableCell>
                          <TableCell align="left">{loginType}</TableCell>
                          {/* <TableCell align="left">{rating}</TableCell> */}
                          <TableCell align="left">{moment(createdAt).format("MMM, DD, YYYY")}</TableCell>
                          <TableCell align="left">{moment(lastOpenedApp).format("MMM, DD, YYYY")}</TableCell>
                          <TableCell align="left">{_id}</TableCell>
                          <TableCell align="left">{result} </TableCell>
                          <TableCell align="left">{results} </TableCell> 
                          <TableCell align="left">{resultData} </TableCell> 
                          <TableCell align="left">{subResult} </TableCell>
                           <TableCell align="left">{subResults} </TableCell>
                           <TableCell align="left">{subResultData} </TableCell>
                          <TableCell align="right" sx={{ display: "flex", flexDirection: "column-reverse" }}>
                            <Button> onClick navigate toPath{ '/user/${_id}'} 
                              title: 'View User Details'
                              </Button>
                          </TableCell>
                          <TableCell align="left">
                            <Button color={status === 1 ? 'error' : 'success'} variant="contained">
                              {sentenceCase(status === 0 ? "Activate" : "Deactivate")}
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Edit
          </MenuItem>
          <MenuItem sx={{ color: 'error.main' }}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem>
        </Popover>
        <AskDialog
          open={open1}
          title="User Activate/Deactivate"
          description={`Do you want to ${selectedData.status === 0 ? "activate" : "deactivate"} this user?`}
          setOpen={setOpen1}
          onCancel={() => setOpen1(false)}
          onOk={() => {
            setOpen1(false);
            deactivate();
          }}
        />

        <AskDialog> 
          open={open2}
          title="User Delete"
          description={`Do you want to delete this user?`}
          setOpen={setOpen2}
          onCancel={() => setOpen2(false)}
          onOk={() => {
            setOpen2(false);
            deleteUser();
          }}
        </AskDialog>
      </>
    );
  }
