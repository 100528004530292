import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Stack, Avatar, Grid } from '@mui/material';
import { image_base_url } from 'src/services/endpoints';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const orderTypes = {
    "0": "Requested",
    "1": "Accepted",
    "2": "Rejected",
    "3": "Picked Up",
    "4": "Delieverd",
    "5": "Received",
    "6": "Cancelled"
}
export default function FullScreenDialog({ item, setItem }) {


    const handleClose = () => {
        setItem(null);
    };

    return (
        <Dialog
            fullScreen
            open={Boolean(item)}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {item?.from?.address} - {item?.to?.address} ({orderTypes[`${item?.status}`]})
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box p={4}>
                <Typography variant='h5'>Destination Date & Time</Typography>
                <Box display={"flex"} flexDirection="column" sx={{ marginLeft: "10px" }}>
                    <Box>Name: {item?.toDate}</Box>
                </Box>
                <Grid container mt={2} spacing={2}>
                    <Grid item>
                        <Typography variant='h5'>Package Details</Typography>
                        <Stack >
                            <Box mb={2} display={"flex"} flexDirection="column" sx={{ marginLeft: "10px" }}>
                                <Box>Package Type: {item?.packageSelected}</Box>
                                <Box>Description: {item?.packageDescription}</Box>
                            </Box>
                            <Typography mb={2}>Package Images</Typography>
                            {item?.packageImages?.map(x => (
                                <a href={image_base_url + x} target="_blank" style={{ width: "100px" }} ><img alt="package images" src={image_base_url + x} style={{ height: "100px", width: "100px" }} /></a>
                            ))}
                            <Typography mb={2}>PickedUp Images</Typography>
                            {item?.verifiedImages?.map(x => (
                                <a href={image_base_url + x} target="_blank" style={{ width: "100px" }} ><img alt="package images" src={image_base_url + x} style={{ height: "100px", width: "100px" }} /></a>
                            ))}
                        </Stack>
                    </Grid>
                    <Grid item ml={2}>
                            <Typography>Going By : {item?.goingBy}</Typography>
                            <Typography>Status : {orderTypes[`${item?.status}`]}</Typography>
                            <Typography>Tracking ID : #{item?.trackId}</Typography>
                            <Typography>Payment Status : {item?.paymentStatus==0?"Pending":"Compelted"}</Typography>
                            <Typography>Payment Status : {item?.paymentStatus==0?"Pending":"Compelted"}</Typography>

                    </Grid>
                </Grid>
                <Grid container mt={2} spacing={2}>
                    <Grid item>
                        <Stack >
                            <Typography variant='h5'>Shipper</Typography>
                            <Avatar sx={{ marginLeft: "10px", marginTop: "5px" }} alt={item?.requested_by?.firstName} src={image_base_url + item?.requested_by?.profilePic} />
                            <Box display={"flex"} flexDirection="column" sx={{ marginLeft: "10px" }}>
                                <Box>Name: {item?.requested_by?.firstName == "" ? "????" : item?.requested_by?.firstName}</Box>
                                <Box>Email: {item?.requested_by?.email}</Box>
                                <Box>Phone: {item?.requested_by?.phone==""?"None":item?.requested_by?.phone}</Box>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item>

                        <Stack >
                            <Typography variant='h5'>Transporter</Typography>
                            <Avatar sx={{ marginLeft: "10px", marginTop: "5px" }} alt={item?.requested_to?.firstName} src={image_base_url + item?.requested_to?.profilePic} />
                            <Box display={"flex"} flexDirection="column" sx={{ marginLeft: "10px" }}>
                                <Box>Name: {item?.requested_to?.firstName == "" ? "????" : item?.requested_to?.firstName}</Box>
                                <Box>Email: {item?.requested_to?.email}</Box>
                                <Box>Phone: {item?.requested_to?.phone==""?"None":item?.requested_to?.phone}</Box>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack >
                            <Typography variant='h5'>Receiver Details</Typography>
                            <Box display={"flex"} flexDirection="column" sx={{ marginLeft: "10px" }}>
                                <Box>Name: {item?.reciever?.name}</Box>
                                <Box>Email: {item?.reciever?.email}</Box>
                                <Box>Phone: +1{item?.reciever?.phone}</Box>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    );
}