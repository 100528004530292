import { useEffect, useRef, useState } from 'react';

import { Helmet } from 'react-helmet-async';
// @mui
import { TextField, Button, Container, Stack, Box ,Typography} from '@mui/material';
import JoditEditor from 'jodit-react';
import { useDispatch, useSelector } from 'react-redux';
import hit from 'src/services/manager';
import { setLoader } from 'src/redux/loader';
import { setMiscData } from 'src/redux/miscdata';
import { setToast } from 'src/redux/toast';

// components
// mock

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

export default function SubsPage() {

    const miscdata = useSelector(state => state.miscdata)?.data
    const dispatch = useDispatch()
    const [minTAmount, setMinTAmount] = useState("")
    const [commissionPer, setCommissionPer] = useState("")
    const [cancellationPer, setCancellationPer] = useState("")
    const writes=useSelector(state=>state.navs)?.write
    const user=useSelector(state=>state.auth)?.user

    useEffect(() => {
        if (miscdata?.terms) {
            setMinTAmount(miscdata?.minTAmount)
            setCommissionPer(miscdata?.commissionPer)
            setCancellationPer(miscdata?.cancellationPer)
        }
    }, [miscdata])

    const config = {
        readonly: false, // all options from https://xdsoft.net/jodit/doc/,
        placeholder: 'Start typings...'
    }

    const submit = async () => {
        try {
            dispatch(setLoader(true))
            let res = await hit("/admin/misc", "post", { minTAmount, commissionPer, cancellationPer })
            if (!res.err) {
                dispatch(setMiscData(res.data))
                dispatch(setToast({ show: true, message: "Updated Successfully!", severity: "success" }))

            } else {
                dispatch(setToast({ show: true, message: res?.msg, severity: "error" }))
            }
        } catch (err) {

        } finally {
            dispatch(setLoader(false))

        }
    }

    return (
        <>
            <Helmet>
                <title> Dashboard: Sub | SuperSenses </title>
            </Helmet>

            <Container>
                <Typography variant='h4'>Control Sub</Typography>
                <Stack direction="row" alignItems="center" justifyContent={"center"} my={5}>
                    <TextField value={minTAmount} onChange={e => {
                        setMinTAmount(e.target.value)
                    }} name="tamount" label="Minimum Transaction($)" />
                    <TextField value={cancellationPer} sx={{ mx: "10px" }} onChange={e => {
                        setCancellationPer(e.target.value)
                    }} name="cancellation" label="Cancellation Charges(%)" />
                    <TextField value={commissionPer} onChange={e => {
                        setCommissionPer(e.target.value)
                    }} name="commision" label="Commision Per(%)" />

                </Stack>
                <div>

                </div>
                <Box display="flex" justifyContent={"center"} mt={4}>
                    <Button onClick={() => {
                        if(user?.role=="admin"){
                            submit()
                        }else if(user?.role=="staff"){
                            if(writes?.includes("subs")){
                                submit()
                            }else{
                                dispatch(setToast({ show: true, message: "Sorry you are not authorised!", severity: "error" }))

                            }
                        }
                        
                    }} variant='contained' >
                        Update
                    </Button>
                </Box>
            </Container>
        </>
    );
}
