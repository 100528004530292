import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link, Box, IconButton, InputAdornment, TextField, Checkbox, Typography, Stack, Divider, FormControl, InputLabel, Select, MenuItem, Badge } from '@mui/material';
import { useDispatch } from 'react-redux';
import Iconify from '../iconify';
import { useState } from 'react';
import { useEffect } from 'react';
import { image_base_url } from 'src/services/endpoints';
import hit from 'src/services/manager';
import { setLoader } from 'src/redux/loader';
import { setToast } from 'src/redux/toast';

const conditions = [
    { value: 4, label: "Super" },
    { label: "Dominant", value: 3, },
    { label: "Normal", value: 2, },
    { label: "Low", value: 1, },
]

const tests = [
    { label: "hearing", value: 0 },
    { label: "taste", value: 1, },
    { label: "touch", value: 2, },
    { label: "smell", value: 3, },
    { label: "vision", value: 4, }
]

const categorys = [
    { label: "About You", value: 1 },
    { label: "Try This", value: 2 },
    { label: "Your Sensory Tale", value: 4 },
    { label: "Sensory Journeys", value: 5 },
    { label: "Evolutionary Gifts", value: 6 },
    { label: "Sage Advice", value: 7 },
    { label: "Wonders of Science", value: 8 },
    { label: "Your Advantage", value: 9 },
    { label: "Did you Know?", value: 10 },
    { label: "Your Health", value: 11 },
    { label: "Pro Tip", value: 12 },
]

let initialState={
    headline: "",
    text: "",
    icon: null,
    image: null,
    shareLink: "",
    test: tests[0].value,
    condition: conditions[0].value,
    category: categorys[0].value
}


export default function AddFeed({ title, open, setOpen, onSubmit, onCancel, selectedData }) {
    const [data, setData] = useState(initialState)

    const dispatch = useDispatch()

    useEffect(() => {
        if (open) {
            if (selectedData) {
                setData(selectedData)
            } else {
                setData(initialState)
            }
        } else {
            setData(initialState)
        }

    }, [open, selectedData])

    const handleFile = async (e) => {
        try {
            if (e.target.files[0]) {
                dispatch(setLoader(true))
                const formdata = new FormData()
                formdata.append("file", e.target.files[0])
                let x = await hit("/uploads", "post", formdata)
                console.log(x)
                if (!x.err) {
                    if (x?.data?.length > 0) {
                        setData({ ...data, icon: x?.data[0] })
                        // submit({ wishGif: x?.data[0] })
                    }
                }
            }
        } catch (err) {
            console.log(err)
        } finally {
            dispatch(setLoader(false))
        }
    }

    const handleFile1 = async (e) => {
        try {
            if (e.target.files[0]) {
                dispatch(setLoader(true))
                const formdata = new FormData()
                formdata.append("file", e.target.files[0])
                let x = await hit("/uploads", "post", formdata)
                console.log(x)
                if (!x.err) {
                    if (x?.data?.length > 0) {
                        setData({ ...data, image: x?.data[0] })
                        // submit({ wishGif: x?.data[0] })
                    }
                }
            }
        } catch (err) {
            console.log(err)
        } finally {
            dispatch(setLoader(false))
        }
    }


    const submitData = async (e) => {
        try {

            dispatch(setLoader(true))
            if(data?.headline?.trim()==""||data?.text?.trim()==""){
                return
            }
            delete data.icon
            if(selectedData?._id){
                let x = await hit("/feeds/"+selectedData?._id, "patch", data)
                if (!x.err) {
                    dispatch(setToast({show:true,message:"feed updated!"}))
                    onSubmit()
                }else{
                    dispatch(setToast({show:true,message:x.msg,severity:"error"}))
    
                }
            }else{
                let x = await hit("/feeds", "post", data)
                if (!x.err) {
                    dispatch(setToast({show:true,message:"feed added!"}))
                    onSubmit()
                }else{
                    dispatch(setToast({show:true,message:x.msg,severity:"error"}))
    
                }
            }
           
        } catch (err) {
            console.log(err)
        } finally {
            dispatch(setLoader(false))
        }
    }
    return (
        <Dialog
            open={open}

            onClose={() => { setOpen(false) }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ width: "100vw" }}
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent sx={{ maxHeight: "70vh", overflowY: "auto" }}>
                {/* <DialogContentText id="alert-dialog-description">
                    {description}
                </DialogContentText> */}
                <Box >

                    <Divider sx={{ marginY: "10px" }} />
                    <Typography>Headline*</Typography>
                    <TextField value={data?.headline} onChange={e => {
                        setData({ ...data, headline: e.target.value })
                    }} placeholder='Headline' fullWidth sx={{}} />
                    <Divider sx={{ marginY: "10px" }} />
                    <Typography>Message Content*</Typography>
                    <TextField multiline rows={4} value={data?.text} onChange={e => {
                        setData({ ...data, text: e.target.value })
                    }} placeholder='Message Content' fullWidth sx={{}} />
                    <Divider sx={{ marginY: "10px" }} />
                    <Stack direction={"row"}>
                        {/* <Box>
                            <Typography>Add Icon*</Typography>
                            <div style={{ position: "relative" }}>
                                <Badge
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    overlap="circle"
                                    badgeContent={"+"}
                                    color="primary"
                                >
                                    <img src={image_base_url + (data?.icon ? data?.icon : "public/cover.png")} style={{ height: "80px", width: "80px", marginTop: "10px" }} />
                                </Badge>
                                <Box component={"input"} type="file" id="file-selector" accept=".png" onChange={handleFile} sx={{ position: "absolute", height: "100%", width: "100%", top: 0, left: 0, opacity: 0, zIndex: 111 }}></Box>
                            </div>
                        </Box> */}
                        <Box sx={{ }}>
                            <Typography>Add Image</Typography>
                            <div style={{ position: "relative" }}>
                                <Badge
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    overlap="circle"
                                    badgeContent={"+"}
                                    color="primary"
                                >
                                    <img src={image_base_url + (data?.image ? data?.image : "public/cover.png")} style={{ height: "150px", objectFit: "contain", width: "300px", marginTop: "10px" }} />
                                </Badge>
                                <Box component={"input"} type="file" id="file-selector" accept=".png" onChange={handleFile1} sx={{ position: "absolute", height: "100%", width: "100%", top: 0, left: 0, opacity: 0, zIndex: 111 }}></Box>
                            </div>
                        </Box>
                    </Stack>
                    <Divider sx={{ marginY: "10px" }} />
                    <Typography>Share link</Typography>
                    <TextField value={data?.shareLink} onChange={e => {
                        setData({ ...data, shareLink: e.target.value })
                    }} placeholder='Share Link' fullWidth sx={{}} />
                    <Divider sx={{ marginY: "10px" }} />
                    <Box >
                        <Stack direction={"row"} style={{ width: "500px" }}>
                            <FormControl fullWidth>
                                <InputLabel id="select-1">Test Select*</InputLabel>
                                <Select
                                    labelId="select-1"
                                    id="demo-simple-select"
                                    value={data.test}
                                    label="Test"
                                    onChange={(e) => {
                                        setData({ ...data, test: e.target.value })
                                    }}
                                >
                                    {tests?.map(x => {
                                        return <MenuItem value={x.value}>{x.label}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ marginLeft: "10px" }} fullWidth>
                                <InputLabel id="select-2">Category Select *</InputLabel>
                                <Select
                                    labelId="select-2"
                                    id="demo-simple-select1"
                                    value={data.category}
                                    label="Category"
                                    onChange={(e) => {
                                        setData({ ...data, category: e.target.value })
                                    }}
                                >
                                    {categorys?.map(x => {
                                        return <MenuItem value={x.value}>{x.label}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack direction={"row"}>
                            <FormControl sx={{ marginTop: "10px" }} fullWidth>
                                <InputLabel id="select-2">Condition*</InputLabel>
                                <Select
                                    labelId="select-2"
                                    id="demo-simple-select1"
                                    value={data.condition}
                                    label="Category"
                                    onChange={(e) => {
                                        setData({ ...data, condition: e.target.value })
                                    }}
                                >
                                    {conditions?.map(x => {
                                        return <MenuItem value={x.value}>{x.label}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Stack>
                    </Box>
                </Box>

            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Cancel</Button>
                <Button onClick={() => {
submitData()
                }} autoFocus>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}